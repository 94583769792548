* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
h1 {
  font-size: 2rem;
  line-height: 1.3;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.4;
}

@media (min-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.75rem;
  }
}

@media (min-width: 1024px) {
  h2 {
    font-size: 2rem;
  }
}
button {
  cursor: pointer;
}

:root {
  --primary-color: rgb(66, 66, 66);
  --primary-color-onhover: black;

  --secondary-background-color: rgb(232, 232, 232);
  --secondary-background-color-onhover: rgb(214, 214, 214);
}
