/* VideosSection.css */
.video-container {
  display: flex;
  background-color: rgb(255, 255, 255);
  justify-content: space-around;
  flex-flow: row nowrap;
  margin: 5em 0;
}

.video-container .video-card {
  display: flex;
  flex-flow: column nowrap;
  width: 35vw;
  height: 4vw;
  margin: 5px;
  position: relative;
  border-radius: 25px;
  cursor: pointer;
}

.video-container video {
  object-fit: cover;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.video-container .video-card h3 {
  font-size: 0.9em;
  position: absolute;
  color: white;
  bottom: 40px;
  left: 0;
  right: 0;
  text-align: center;
}

.video-section-img {
  width: 100%;
  position: relative;
}

.video-player {
  display: none;
}

/* Media Query for Desktop (min-width: 768px) */
@media (min-width: 768px) {
  .video-container {
    justify-content: center;
  }

  .video-container .video-card {
    height: 40vw;
    width: 23vw;
  }

  .video-container .notch {
    height: 1.3vw;
  }

  .video-container .video-card h3 {
    font-size: 3em;
  }

  .hero-img-container h1 {
    font-size: 6em;
  }

  .video-player {
    display: block;
  }

  .mobile-image {
    display: none;
  }
}

/* Additional styling for mobile devices (max-width: 767px) */
@media (max-width: 767px) {
  .video-container .video-card {
    width: 100%; /* Show the entire width of the image */
    height: auto; /* Let the height adjust to the image's aspect ratio */
  }

  .video-container .video-card h3 {
    font-size: 1.8em; /* Adjust the font size for better visibility on mobile */
    bottom: 20px; /* Adjust the position of the text on the image */
  }

  .mobile-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh; /* Set a specific height to the image container */
  }

  .mobile-image img {
    max-width: 100%; /* Show the entire width of the image */
    max-height: 100%; /* Show the entire height of the image */
    object-fit: contain; /* Fit the entire image into the container without distortion */
  }

  .video-player {
    display: none; /* Hide the video on mobile */
  }
}
